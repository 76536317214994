.navbar-container {display: grid;grid-template-columns: auto auto;align-items: center;padding: 15px 20px;justify-content: space-between;user-select: none;}
.navbar-menu span {color: #777777;margin: 0 10px 0 0;}
.navbar-action {display: grid;grid-template-columns: auto auto auto;align-items: center;}
.navbar-action img[alt='icon'] {width: 46px;margin-left: 20px;cursor: pointer;}
.wallet-card {display: grid;grid-template-columns: 70px auto;}
.wallet-card {border-radius: 5px;overflow: hidden;margin: 0 0 0 20px;min-width: 220px;}
.wallet-card p, .wallet-card h5, .wallet-card h6 {margin: 0;line-height: 1;}
.wallet-card p {font-size: 14px;font-weight: 600;}
.wallet-balance {background-color: #FFF;padding: 5px 10px 5px 10px;}
.wallet-balance h6 {color: #777;font-size: 14px;margin-bottom: 2px;}
.wallet-balance h5 {color: #000;font-size: 20px;font-weight: 500;}
.wallet-balance span {float: right;cursor: pointer;margin-top: 10px;}
.add-balance {text-align: center;color: #FFF;padding: 5px;cursor: pointer;}
.add-balance p:first-child {font-size: 20px;}
.user-detail {margin-bottom: 0;}
.user-detail span {font-weight: 600;}
.user-dropdown {position: absolute;width: 300px;background: #FFF;right: 0;padding: 10px;border: 1px solid #EEE;margin: 0 20px 0 0;z-index: 99;}
.user-dropdown > img {height: 10px;width: 15px;float: right;margin-top: 0px;margin-right: 10px;}
.user-dropdown > span {color: #777;font-size: 12px;}
.user-dropdown > h5 {font-weight: 500;margin-bottom: 10px;}
.user-dropdown > h5 > span {font-size: 16px;display: block;font-weight: 400;}
.user-dropdown > p {border-top: 1px solid #EEE;margin: 0;padding: 10px 0;text-transform: uppercase;}
.user-dropdown > p > span {font-size: 14px;font-weight: 500;}
.user-dropdown > p > span > a {color: #1C2347;text-decoration: none;}
.create-id {background: #FFF;font-weight: 600!important;padding: 8px 20px;display: block;text-align: center;border-radius: 5px;cursor: pointer;}
.user-dropdown > p > i {cursor: pointer;}
.user-dropdown > div {padding: 10px;width: calc(100% + 20px);margin: 0 0 -10px -10px;background: #F1F1F1;}
.key-copied-notification {position: fixed;z-index: 999;right: 20px;background: #fff;padding: 10px;border-radius: 10px;text-align: center;width: 200px;bottom: 20px;font-size: 18px;font-weight: 500;color: #ff6290;box-shadow: 0 0 10px 0 #bbb;user-select: none;animation-name: key-copied;animation-duration: 0.3s;}
.demo-call-container {position: fixed;height: 100vh;width: 100%;display: grid;align-items: center;justify-content: center;z-index: 99; left: 0; top: 0;}
.demo-call-background {position: absolute;background:#00000060;left:0;top:0;z-index: 999;width: 100%;height: 100vh;}
.demo-call-page {border-radius: 0.3rem; z-index: 9999; background-color: #FFF;display: grid;grid-template-columns: 1fr 1fr;width: 1100px;max-width: 100%;box-shadow: 0 0 10px 0 #CCC;}
.demo-call-left-side {background-color: #599FDC40;text-align: center;padding: 50px 50px 0 50px;display: grid;justify-items: center;align-content: space-between;}
.demo-call-left-side img[alt="democall-icon"] {height: 50px;}
.demo-call-left-side p {font-size: 18px;margin: 10px 0;}
.demo-call-right-side {padding: 30px 50px;}
.demo-call-right-side h3 {font-size: 30px;font-weight: 600;margin: 0;display: grid; grid-template-columns: auto auto; justify-content: space-between;}
.demo-call-right-side h3 img {cursor: pointer;}
.demo-call-right-side > div {margin-top: 30px;}
@keyframes key-copied {
    from {right: -220px;}
    to {right: 20px;}
}

.mobile-navbar-action {display: block;/* display: grid;grid-template-columns: auto auto;align-items: center; */}
.mobile-wallet-container {display: grid;grid-template-columns: 1fr 1fr;}

/* ==========---------- login bonus ----------========== */
.login-bonus-container {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;}
.login-bonus-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.login-bonus {position: absolute;z-index: 9999;background-image: url(./login-bonus.svg);width: 543px;height: 493px;left: calc(50% - 271.5px);top: calc(50vh - 246.5px);text-align: center;color: #FFF;padding: 30px 20px;display: grid;align-content: space-between;overflow: hidden;}
.login-bonus h5 {font-weight: 600;font-size: 26px;margin:0;text-transform: uppercase;margin: 10px 0 5px 0;}
.login-bonus p {font-family: 'Rubik';font-weight: 400;font-size: 16px;margin-bottom:0;}
.login-bonus span {color: #DB1667;background:#1C2347D6;font-family: 'Rubik';font-weight: 600;position: absolute;padding: 4px 50px;transform: rotate(-45deg);margin-left: -35px;margin-top: 15px;}
.login-bonus img {position: absolute;right: 0;margin: 20px;cursor: pointer;}

/* ==========---------- credit amount ----------========== */
.bonus-credit-container {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
.bonus-credit-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.bonus-credit-box {position: absolute;z-index: 9999;background-image: url(./confetti.gif);width: 600px;text-align: center;border-radius: 5px;}
.bonus-credit-box h5 {font-weight: 600;font-size: 30px;margin:0;text-transform: uppercase;margin: 10px 0 5px 0;}
.bonus-credit-box p {font-family: 'Rubik';font-weight: 400;font-size: 18px;margin-bottom:0;color: #929191;}
.bonus-credit-box p span {color: #1C2347;}
.bonus-credit-box img[alt="times"] {position: absolute;right: 0;margin: 20px;cursor: pointer;}
.bonus-credit-box button {width: 100%;margin: 20px 0;}
.bonus-credit-box > div {padding: 20px 50px;}
.credit-amount {background: #66B8FF14;font-size: 50px;font-family: 'Rubik';font-weight: 500;margin: 20px 0;padding: 10px;border-radius: 5px;}

/* ==========---------- Razorpay ----------========== */
.razorpay-container {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 99;display: grid;place-items: center;}
.razorpay-background {position: absolute;left: 0;top: 0;width: 100%;height: 100vh;z-index: 999;background: #00000050;}
.razorpay {position: absolute;z-index: 9999;background: #FFF;width: 900px;max-width: 100%;border-radius: 5px;padding: 25px;display: grid;grid-template-columns: 1fr 1fr;overflow: hidden;}
.contact-admin {position: absolute;z-index: 9999;background: #FFF;width: 550px;max-width: 100%;border-radius: 5px;padding: 25px;overflow: hidden;}
.razorpay div:first-child {text-align: center;}
.razorpay h3 {font-weight: 500;margin: 0 0 10px 0;font-size: 25px;display: grid;grid-template-columns: auto auto;align-items: center;justify-content: space-between;}
.razorpay img[alt="times"] {cursor: pointer;}
.razorpay img[alt="add-balance"] {width: 350px;max-width: 100%;margin-right: 15px;}
.razorpay-error {margin-bottom: 0;font-weight: 500;font-size: 16px;color: #DC3545;}
.razorpay-loader {display: grid;place-items: center;align-content: center;height: 338px;}
.razorpay-loader span {font-size: 40px;}
.razorpay-loader p {font-size: 22px;margin-bottom: 0;font-weight: 600;}

@media only screen and (max-width: 1360px) {
    .user-detail {font-size: 20px;}
    .user-detail span {display: block;}
}

@media only screen and (max-width: 1230px) {
    .navbar-container {grid-template-columns: 1fr;}
    .user-detail {font-size: 24px;margin-bottom: 10px;}
    .user-detail span {display: inline-block;}
}

@media only screen and (max-width: 600px) {
    .navbar-container {display: block;}
    .navbar-action {display: none;}
    .wallet-card {grid-template-columns: 50px 115px;margin: 15px 15px 0 0;min-width: auto;width: max-content;}
    .wallet-balance {padding: 5px;}
    .wallet-balance h6 {font-size: 12px;}
    .wallet-balance h5 {font-size: 16px;}
    .navbar-container {padding: 10px;}
    .user-detail {margin-bottom: 10px;}
    .razorpay {grid-template-columns: 1fr;width: calc(100% - 10px);overflow: hidden;}
    .razorpay img[alt="add-balance"] {margin-right: 0px;margin-bottom: 15px;}
    .razorpay img[alt="times"] {position: absolute;top: 20px;right: 20px;}
    .razorpay-container {position: fixed;}
    .login-bonus {width: calc(100% - 10px);left: 5px;border-radius: 5px;background-position: -80px;}
    .bonus-credit-box {width: calc(100% - 10px);background-position: -120px;}
    .navbar-menu {margin-bottom: 15px;}
    .navbar-menu span {font-size: 14px;margin: 0 15px 0 0;}
    .demo-call-page {width: calc(100% - 20px);margin: 10px;grid-template-columns: 1fr;height: calc(100vh - 20px);overflow: scroll;}
    .demo-call-left-side {padding: 30px 30px 0 30px;}
    .demo-call-left-side img[alt="invincibleocean"]{width: 70%;}
    .demo-call-right-side {padding: 20px 30px 30px 30px;}
    .demo-call-right-side > div {margin-top: 10px;}
    .demo-call-right-side h3 {font-size: 25px;}
    .demo-call-right-side h3 img {position: absolute;top: 30px;right: 30px;}
}

@media only screen and (min-width: 601px) {
    .mobile-navbar-action {display: none;}
}
