.invincible-ocean-sidemenu {color: #FFF;background-color: #1C2347;overflow-y: scroll;border-right: 1px solid #F1F1F1;}
.invincible-ocean-sidemenu::-webkit-scrollbar{background-color:#FAFCFD;width: 0px;}
.invincible-ocean-sidemenu img[alt="invincible-logo"] {width: 200px;margin: 15px 0 0 15px;}
.sidemenu-container {height: calc(100vh - 140px);}
.sidemenu-box {margin: 50px 0 0 0;}
.sidemenu-box h6 {padding-left: 20px;margin-bottom: 6px;font-size: 14px;font-weight: 500;}
.sidemenu-box ul {list-style-type: none;padding-left: 0;margin-bottom: 0;}
.sidemenu-box ul li > a, .sidemenu-box ul li > span {color: #FFF;text-decoration: none;padding: 10px 20px;display: inline-block;width: 100%;font-size: 18px;font-weight: 500;cursor: pointer;user-select: none;}

/* .sidemenu-box ul li > a{transition: transform 0.1s;} */
/* .sidemenu-box ul li > a:hover{transform:scale(0.95)} */

.sidemenu-box ul li a img, .sidemenu-box ul li span img {margin-right: 15px;width: 15px;margin-top: -3px;}
.sidemenu-box ul li > div {display: grid;grid-template-columns: 0 1fr;padding-left: 30px;margin-bottom: 10px;}
.sidemenu-box ul li > div:before {content: '';display: block;width: 1px;background: #6B6B6B;}
.sidemenu-box ul li div p {margin-bottom: 0;}
.sidemenu-box ul li div p span {font-weight: 500;display: block;margin-top: 25px;margin-bottom: -10px;}
.sidemenu-box ul li div p span:first-child {margin-top: 15px;}
.sidemenu-box ul li div p span:before {content: '---- ';letter-spacing: -2px;margin-right: 4px;color: #6B6B6B;}
.sidemenu-box ul li div p span a {color: #FFF;text-decoration: none;}
.dashboard-image {height: 90px;width: 180px;padding: 20px 0 0 20px;}
.dashboard-image img {max-width: 100%;max-height: 100%;}
.logout-modal {
    background-color: #FFF;
    color: #1C2347;
    padding: 25px;
    text-align: center;
    border-radius: 0.3rem;
    width: 450px;
}
.logout-modal h4 {
    font-weight: 500;
}
.logout-modal h5 {
    margin-bottom: 30px;
}

@media only screen and (max-width: 600px){
    .invincible-ocean-sidemenu {position: absolute;width: 75%;left: -75%;}
    .sidemenu-box img[alt="draft-icon"]{margin-top: -2px;}
    .mobile-top-nav {background-color: #FFF;padding: 10px;display: grid;grid-template-columns: 35px auto;align-items: center;justify-content: space-between;}
    .mobile-top-nav img[alt="bars"] {width: 30px;}
    .mobile-top-nav img[alt="invincible-logo"] {width: 200px;}
    .mobile-side-nav {position: fixed;top: 0;left: 0;width: 100%;z-index: 9;height: 100vh;}
    .mobile-sidemenu-background {background: #000000CC;position: absolute;top: 0;left: 0;width: 100%;z-index: 99;height: 100vh;}
    .mobile-sidemenu-background img {width: 30px;float: right;margin-top: 20px;margin-right: calc(10% - 15px);}
    .mobile-sidemenu {background-color: #FFF;position: absolute;top: 0;width: 80%;z-index: 999;padding-top: 10px;height: 100vh;animation-name: mobile-sidemenu;animation-duration: 0.3s;}
    @keyframes mobile-sidemenu {
        from {left: -75%;}
        to {left: 0;}
    }
    .mobile-sidemenu img[alt="invincible-logo"] {width: 200px;max-width: 100%;margin: 15px 0 0 10px;}
    .sidemenu-box {margin: 30px 0 0 0;}
    .dashboard-image {height: 50px;width: 150px;padding: 0 0 0 20px;}
}

@media only screen and (min-width: 601px){
    .mobile-nav {display: none;}
}