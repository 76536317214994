.registration-modal-container {position: absolute;left:0;top:0;height: 100vh;width: 100%;display: grid;align-items: center;justify-content: center;z-index: 99;animation-name: registration-modal;animation-duration: 0.3s;}
@keyframes registration-modal {
    from {opacity: 0.5;}
    to {opacity: 1;}
}
.registration-modal-background {position: absolute;background:#00000060;left:0;top:0;z-index: 999;width: 100%;height: 100vh;}
.registration-modal-box {z-index: 9999;width: 1100px;max-width:100%;height:650px;max-height: 100vh;overflow-y: scroll;background: #FFF;display: grid;grid-template-columns: 470px auto;}
.registration-modal-box::-webkit-scrollbar{width: 0;}
.registration-modal-box h3 {font-size: 30px;font-weight: 600;padding: 20px 20px 10px 100px;margin-bottom: 0;display: grid;grid-template-columns: auto auto;justify-content: space-between;align-items: center;}
.registration-modal-box h3 img[alt="times-circle"] {cursor: pointer;}
.registration-modal-left-side {text-align: center;background-size: 100%;padding: 60px 5px;display: grid;align-content: space-between;justify-items: center;}
.registration-modal-left-side > div > img {width: 50px;}
.registration-modal-left-side > div > h4 {font-weight: 600;margin: 15px 0 0 0;font-size: 28px;}
.registration-modal-left-side > div > h4 > span {color:#FF7D90;}
.registration-modal-left-side > div > p {color: #757575;margin-bottom: 0;}
.registration-modal-content {width: calc(100% - 200px);margin: 30px 0 50px 100px;}
/* .login-page-content {width: calc(100% - 60px);} */
.login-page-content input{ border-left: 5px solid;}
.resend-otp {margin-bottom: 25px;text-align: right;margin-top: -15px;font-size: 14px;}
.resend-otp span {color: #1CA9DD;cursor: pointer;text-decoration: underline;}
.terms-and-conditions {font-size: 14px;font-weight: 500;}
.terms-and-conditions a {text-decoration: underline;}
.have-account {color: #929191;margin: 20px 0;font-size: 16px;text-align: center;font-weight: 500;}
.have-account span {cursor: pointer; font-size: 14px;}
.edit-login-number { margin-bottom: 0; text-align: right; margin-top: -15px; font-size: 14px;}
.edit-login-number span{ color: #1CA9DD; cursor: pointer; text-decoration: underline;}
.hide-show-password i {
    float: right;
    margin-right: 10px;
    margin-top: -45px;
    cursor: pointer;
}
.certified-logo{display: grid; grid-template-columns: 1fr 1fr 1fr 1fr; margin-top: 15px; padding: 25px;}
.footer-content{margin-top: 40px; text-align: center; font-weight: 300; font-size: 12px; }

/* forgot password  */
.forgot-password{float: right; text-decoration: underline; cursor: pointer;}
.back-btn >i{cursor: pointer;float: right;}
.export-box {padding: 25px;background: white; width: 500px; border-radius: 10px;}


/* Pop-up  */
.custom-container {position: fixed; left: 0; top: 0; width: 100%; height: 100vh; z-index: 99; display: grid; place-items: center;}
.custom-background {position: absolute; left: 0; top: 0; width: 100%; height: 100vh; z-index: 999; background: #00000050;}
.pop-up-form-custom {position: absolute; z-index: 9999; background: #FFF; padding: 40px 30px; width: 450px; opacity: 2; max-width: 100%; border-radius: 5px; overflow: hidden; text-align: center; overflow: hidden;}
.pop-up-form-custom img {width: 160px;}
.pop-up-form-custom h4 {margin: 15px 0 10px 0;font-size: 20px;font-weight: 500;}
.pop-up-form-custom p {color: #929191;}


.verify-mail {display: grid;height: 100vh;align-content: center;text-align: center;justify-items: center;}
.verify-mail img {width: 100px;}
.verify-mail h4 {font-weight: 500;margin-top: 20px;margin-bottom: 20px;}

.reset-password-container {
    background: #FFF;
    padding: 20px;
}
.reset-password-box {
    background: #6F6EB314;
    padding: 30px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
}
.reset-password-box > div:nth-child(2) {
    text-align: center;
    padding-left: 20px;
}
.reset-password-box > div:nth-child(2) img {
    width: 350px;
    max-width: 100%;
}
.reset-password-box form {
    background: #FFF;
    padding: 30px;
    max-width: 600px;
}
.reset-password-box form h5 {
    font-size: 18px;
    font-weight: 500;
    border-bottom: 1px solid #E7E7E6;
    padding-bottom: 5px;
    margin-bottom: 15px;
}
.hide-show-password {
    margin-right: 0.625rem;
    float: right;
    cursor: pointer;
}
.instructions {
    font-family: Barlow, sans-serif;
    font-weight: 300;
    font-size: 12px;
}

@media only screen and (max-width: 900px) {
    .reset-password-box {
        display: block;
    }
    .reset-password-box > div:nth-child(2) {
        padding-top: 20px;
        padding-left: 0;
    }
}



@media only screen and (max-width: 600px) {
    .registration-modal-container {position: fixed;}
    .registration-modal-box {width: 100%;grid-template-columns: 1fr;height: calc(100vh - 10px);}
    .registration-modal-box h3 {padding: 10px 20px 0 20px;}
    .registration-modal-content {width: 100%;margin: 30px 0 50px 0;padding: 10px 20px 0 20px;}
    .registration-modal-left-side {padding: 20px 5px;}
    .registration-modal-left-side img {margin-top: 30px;}
    .login-page-content {width: 100%;margin: 0;}
}

.or {display: grid;grid-template-columns: auto 40px auto;align-items: center;justify-items: center;margin-top: 15px;}
.or hr {width: 100%;}
.login-option {border: 1px solid #ccc;border-radius: 5px;padding: 10px;font-weight: 500;margin: 15px 0;font-size: 16px;text-align: center;user-select: none;cursor: pointer;transition: 0.3s;}
.login-option:hover {background-color: #8989891A;transform: scale(1.03);box-shadow: 0 0 4px 0 #0000001A;}
.login-option img {width: 20px;margin-right: 10px;}
